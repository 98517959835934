@import "../../styles/variables/colors";
@import "../../styles/variables/mixins";
.whowearepage {
    background: #360e02;
}
.clockbox {
    display: flex;
    width: 100%;
    padding-bottom: 164px;
    @media screen and (min-width: 0) and (max-width: 767px) {
        flex-flow: column;
        padding-bottom: 70px;
    }
    > div {
        width: calc(100% - 443px);
        position: relative;
        @media screen and (min-width: 0) and (max-width: 767px) {
            width: calc(100% - 85px);
            padding: 0;
            margin-top: 40px;
            flex-flow: column;
            display: flex;
            padding-left: 4px;
        }
        &:before {
            content: "Locations";
            font-family: "Space Mono", monospace;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: -0.26px;
            color: #8c8d71;
            position: absolute;
            left: -130px;
            top: -3px;
            @media screen and (min-width: 0) and (max-width: 767px) {
                position: relative;
                left: 0;
                margin-bottom: 20px;
            }            
        }
        margin-left: auto;
        > div {
            width: 100%;
            display: flex;

            > div {
                max-width: 317px;
                align-items: flex-start;
                padding: 0;
                .timetitle {
                    display: flex;
                    flex-flow: column;
                    padding-bottom: 25px;
                    font-family: "CircularStd";
                    font-size: 18px;
                    font-weight: normal;
                    letter-spacing: -0.29px;
                    color: #f0f700;
                    line-height: 20px;
                    &.oak {
                        color: #862ccb;
                    }
                    & + div {
                        position: relative;
                        > div {
                            position: absolute;
                            width: 110px;
                            height: 110px;
                            &.sunmoon {
                                position: absolute;
                                width: calc(100% + 10px);
                                height: calc(100% + 10px);
                                display: flex;
                                z-index: 999;
                                left: 0;
                                right: 0;
                                margin-left: -8px;
                                margin-top: -6px;
                                img {
                                    width: 45px;
                                    height: 45px;
                                    position: absolute;
                                    left: 35px;
                                    top: -17px;
                                    &.moon {
                                        transform:rotate(88deg);
                                    }
                                }
                            }
                            > div {
                                &:nth-child(2) {
                                    width: 2px;
                                    height: 35px;
                                    top: 19px;
                                }
                                
                            }
                        }
                    }
                }
            }
        }
    }
}
