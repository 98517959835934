@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.projectsherobelow {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    @media screen and (min-width: 0) and (max-width: 767px) {
        width: calc(100% - 85px);
    }
    .titlesec {
        display: flex;
        flex-flow: column;
        margin-bottom: 0;
        .maintitle {
            display: flex;
            align-items: flex-start;
            @media screen and (min-width: 0) and (max-width: 767px) {
                flex-flow: column;
            }
            a {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: "Space Mono", monospace;
                color: #97987c;
                margin-right: 20px;
                line-height: 24px;
                margin-top: 3px;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    order: 2;
                    margin-top: 29px;
                }
                &::before {
                    content: "";
                    background: url(../../../assets/images/visitsite.svg) no-repeat;
                    width: 12px;
                    height: 12px;
                    margin-top: 2px;
                    margin-right: 4px;
                }
            }
            
            .imageareparent {
                width: calc(100% - 165px);
                margin-left: auto;
                display: flex;
                flex-flow: column;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    width: 100%;
                }
                .imagearea {
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        width: 279px;
                        height: 562px;
                        background: url(../../../assets/images/projectmobile.png) no-repeat;
                        position: relative;
                    }
                   
                    img {
                        max-width: 100%;
                        width: 100%;
                        box-shadow: 0 12px 44px 0 rgba(140, 141, 113, .69);
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            width: 260px;
                            height: 436px;
                            position: absolute;
                            left: 9px;
                            top: 47px;
                            box-shadow: none;
                            object-fit: cover;
                        }
                    }
                }
            }
        }
    }
}
