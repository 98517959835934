@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.contactHero {
    position: relative;
    padding-top: 236px;
    display: flex;
    @media screen and (min-width: 0) and (max-width: 767px) {
        padding-top: 117px;
    }
    .parent {
        width: calc(100% - 443px);
        margin-left: auto;
        position: relative;
        @media screen and (min-width: 0) and (max-width: 767px) {
            width: calc(100% - 85px);
        }
        h1 {
            font-family: "CircularStd";
            font-weight: 500;
            font-size: 60px;
            letter-spacing: -0.96px;
            color: #eeeeea;
            max-width: 720px;
            line-height: 65px;
            @media screen and (min-width: 0) and (max-width: 767px) {
                font-size: 40px;
                line-height: 45px;
            }
        }
        form {
            margin-top: 40px;
            width: 100%;
            max-width: 679px;
            position: relative;
            > input {
                width: 100%;
                margin-bottom: 21px;
                height: 66px;
                display: flex;
                align-items: center;
                background-color: #511706;
                font-family: "CircularStd";
                font-size: 20px;
                font-weight: normal;
                letter-spacing: -0.32px;
                color: #fff;
                border: none;
                outline: none;
                padding-left: 15px;
                padding-right: 15px;
                &::placeholder {
                    color: #fff;
                }
                &[type="submit"] {
                    font-family: "Space Mono", monospace;
                    position: absolute;
                    bottom: 4px;
                    background: transparent url(../../../assets/images/homeviewup.svg) no-repeat;
                    width: 111px;
                    height: 29px;
                    display: flex;
                    align-items: center;
                    left: -112px;
                    border: solid 1px #862ccb;
                    font-size: 16px;
                    color: #abac93;
                    background-position: right 13px center;
                    cursor: pointer;
                    transition: all 1s linear;
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        left: 0;
                        position: relative;
                    }
                    &:hover {
                        color: #fff;
                        transition: all 1s linear;
                        background: #862ccb url(../../../assets/images/homeviewupactive.svg) no-repeat;
                        background-position: right 13px center;
                    }
                }
            }
            .react-dropdown {
                outline: none;
                .css-1okebmr-indicatorSeparator {
                    display: none;
                }
                .dropdown__control {
                    background-color: #511706;
                    height: 66px;
                    // margin-bottom: 21px;
                    border: none !important;
                    border-radius: 0;
                    outline: none;
                    border-color: transparent !important;
                    box-shadow: none !important;
                    .dropdown__placeholder,
                    .dropdown__single-value {
                        font-family: "CircularStd";
                        font-size: 20px;
                        font-weight: normal;
                        letter-spacing: -0.32px;
                        color: #fff;
                    }
                    .dropdown__indicator {
                        span {
                            width: 23px;
                            height: 23px;
                            background: url(../../../assets/images/ddlindigator.svg) no-repeat;
                            background-size: 100%;
                            transform: rotate(180deg);
                            transition: all 0.2s linear;
                        }
                        &.css-1gtu0rj-indicatorContainer {
                            span {
                                transform: none;
                                transition: all 0.2s linear;
                            }
                        }
                    }
                }
                .dropdown__menu {
                    margin-top: 2px !important;
                    border-radius: 0;
                    background: #511706;
                    position: relative;
                    > div > div {
                        font-family: "CircularStd";
                        font-size: 20px;
                        font-weight: normal;
                        letter-spacing: -0.32px;
                        color: #fff;
                        border-bottom: 2px solid #3e0b01;
                        &:last-child {
                            border: none;
                        }
                    }
                    .css-9gakcf-option,
                    .css-1n7v3ny-option {
                        background: #d2d2bc;
                        color: #360e02;
                    }
                }
            }
            textarea {
                width: 100%;
                height: 300px;
                resize: none;
                margin-top: 21px;
                border: none;
                outline: none;
                background: #511706;
                margin-bottom: 21px;
                padding: 21px 15px;
                font-family: "CircularStd";
                font-size: 20px;
                font-weight: normal;
                letter-spacing: -0.32px;
                color: #fff;
                &::placeholder {
                    color: #fff;
                }
            }
        }
        .getintouchbox {
            padding-top: 100px;
            padding-bottom: 50px;
            .title {
                font-family: "CircularStd";
                font-size: 60px;
                font-weight: 500;
                letter-spacing: -0.96px;
                color: #fff;
                line-height: 65px;
            }
            p {
                font-family: "CircularStd";
                font-size: 18px;
                font-weight: normal;
                letter-spacing: normal;
                color: #eeeeea;
                line-height: 27px;
                a {
                    color: #eeeeea;
                    text-decoration: none;
                }
            }
        }
    }
}
