@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.kalviesingle {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    @media screen and (min-width: 0) and (max-width: 767px) {
        width: calc(100% - 85px);
    }
   > div {
        display: flex;
        padding-left: 160px;
        flex-flow: row wrap;
        padding-top: 30px;
        padding-bottom: 30px;
        @media screen and (min-width: 0) and (max-width: 767px) {
            padding-left: 0;
        }
        img {
            width: 100%;
        }
    }
}
