@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.HeroSecwhoweare {
    position: relative;
    height: 100vh;
    padding-top: 236px;
    display: flex;
    overflow: hidden;
    @media screen and (min-width: 0) and (max-width: 767px) {
        padding-top: 117px;
        height: auto;
    }
    .parent {
        width: calc(100% - 443px);
        margin-left: auto;
        position: relative;
        @media screen and (min-width: 0) and (max-width: 767px) {
            width: calc(100% - 85px);
            max-width: 258px;
            margin-left: 89px;
        }
        h1 {
            font-family: "CircularStd";
            font-weight: 500;
            font-size: 60px;
            letter-spacing: -0.96px;
            color: #eeeeea;
            max-width: 755px;
            line-height: 65px;
            @media screen and (min-width: 0) and (max-width: 767px) {
                font-size: 40px;
                line-height: 45px;
            }
            span {
                text-decoration: underline;
                
            }
        }
        .herosub {
            max-width: 720px;
            margin-right: auto;
            @media screen and (min-width: 0) and (max-width: 767px) {
                margin-top: 30px;
            }
            p {
                max-width: 390px;
                margin-left: auto;
                font-family: "CircularStd";
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.29px;
                color: #fff;
                font-weight: 400;
            }
        }
    }
}
