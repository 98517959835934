@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.Viewourwork_work {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    padding-top: 236px;
    @media screen and (min-width: 0) and (max-width: 767px) {
        width: calc(100% - 85px);
        margin-left: auto;
        padding-top: 117px;
    }
    .titlesec {
        display: flex;
        flex-flow: column;
        margin-bottom: 66px;
        @media screen and (min-width: 0) and (max-width: 767px) {
            margin-bottom: 40px;
        }
        .maintitle {
            display: flex;
            align-items: flex-start;
            @media screen and (min-width: 0) and (max-width: 767px) {
                flex-flow: column;
            }
            a {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: "Space Mono", monospace;
                color: #97987c;
                margin-right: 20px;
                line-height: 24px;
                margin-top: 3px;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    margin-bottom: 10px;
                }
                &:before {
                    content: "";
                    background: url(../../../assets/images/visitsite.svg) no-repeat;
                    width: 12px;
                    height: 12px;
                    margin-right: 4px;
                }
            }
            p {
                max-width: 720px;
                font-size: 45px;
                font-weight: 500;
                line-height: 55px;
                letter-spacing: -0.72px;
                color: #3e0b01;
                font-family: "CircularStd";
                margin-left: 160px;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    margin-left: 0;
                    font-size: 40px;
                    line-height: 45px;
                }
            }
            .imageareparent {
                width: calc(100% - 165px);
                margin-left: auto;
                display: flex;
                flex-flow: column;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    width: 100%;
                }
                .imagearea {
                    margin-bottom: 120px;
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        margin-bottom: 90px;
                    }
                    img {
                        max-width: 100%;
                        width: 100%;
                    }
                    a.view {
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-family: "Space Mono", monospace;
                        color: #97987c;
                        margin-right: 20px;
                        line-height: 24px;
                        margin-top: 3px;
                        margin-top: 15px;
                        &:before {
                            display: none;
                        }
                        &::after {
                            content: "";
                            background: url(../../../assets/images/homeviewup.svg) no-repeat;
                            width: 12px;
                            height: 12px;
                            margin-left: 4px;
                        }
                    }
                    .shortdesc {
                        font-family: "CircularStd";
                        font-size: 35px;
                        font-weight: normal;
                        letter-spacing: -0.56px;
                        color: #3e0b01;
                        line-height: 45px;
                        margin-top: 20px;
                        margin-bottom: 30px;
                        max-width: 785px;
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            font-size: 18px;
                            line-height: 26px;
                        }
                    }
                    .labelbox {
                        display: flex;
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            flex-flow: row wrap;
                        }
                        > div {
                            display: flex;
                            height: 27px;
                            align-items: center;
                            padding-left: 15px;
                            padding-right: 15px;
                            color: #862ccb;
                            font-family: "Space Mono", monospace;
                            font-size: 16px;
                            border: solid 1px #862ccb;
                            align-self: flex-start;
                            margin-right: 10px;
                            @media screen and (min-width: 0) and (max-width: 767px) {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
