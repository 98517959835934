@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.dashboardslide {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    padding-top: 50px;
    padding-bottom: 100px;
    .titlesec {
        display: flex;
        flex-flow: column;
        margin-bottom: 66px;
        .maintitle {
            display: flex;
            align-items: flex-start;
            p {
                max-width: 720px;
                font-size: 45px;
                font-weight: 500;
                line-height: 55px;
                letter-spacing: -0.72px;
                color: #eeeeea;
                font-family: "CircularStd";
            }
            .imagearea {
                width: calc(100% - 115px);
                margin-left: auto;
                .carousel {
                    display: flex;
                    flex-flow: row wrap;
                    .control-dots {
                        display: none;
                    }
                    .slider-wrapper {
                        width: 100%;
                        order: 1;
                        ul {
                            li {
                                > div {
                                    padding-right: 0;
                                    padding-top: 50px;
                                    padding-left: 50px;
                                    img {
                                        width: 100%;
                                        // box-shadow: 0 12px 44px 0 rgba(140, 141, 113, 0.69);
                                    }
                                    .sidercontent {
                                        font-family: "CircularStd";
                                        max-width: 417px;
                                        font-size: 18px;
                                        font-weight: normal;
                                        color: #3e0b01;
                                        text-align: left;
                                        line-height: 24px;
                                        padding-top: 26px;
                                        padding-bottom: 8px;
                                        span {
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    button {
                        position: relative;
                        top: 0;
                        right: auto;
                        bottom: auto;
                        left: 40px;
                        padding: 0;
                        margin: 0;
                        opacity: 1;
                        background: transparent;
                        display: flex;
                        align-items: center;
                        &.control-prev {
                            order: 2;
                            &::before {
                                content: "";
                                border: none;
                                width: 15px;
                                height: 15px;
                                background: url(../../../assets/images/prevarrowdashboard.svg) no-repeat;
                                background-size: 100%;
                            }
                        }
                        &.control-next {
                            order: 4;
                            left: 0;
                            &::before {
                                content: "";
                                border: none;
                                width: 15px;
                                height: 15px;
                                background: url(../../../assets/images/whatwedo/nextarrowdashboard.svg) no-repeat;
                                background-size: 100%;
                            }
                        }
                    }
                    .carousel-status {
                        order: 3;
                        font-family: "Space Mono", monospace;
                        position: relative;
                        font-size: 16px;
                        letter-spacing: -3.26px;
                        color: #3e0b01;
                        line-height: 16px;
                        margin-left: 35px;
                        text-shadow: none;
                    }
                }
            }
        }
    }
}
