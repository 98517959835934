@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.nextproject {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    padding-top: 74px;
    @media screen and (min-width: 0) and (max-width: 767px) {
        width: calc(100% - 85px);
        padding-top: 50px;
    }
    .titlesec {
        display: flex;
        flex-flow: column;
        margin-bottom: 66px;
        .maintitle {
            display: flex;
            align-items: flex-start;
            @media screen and (min-width: 0) and (max-width: 767px) {
                flex-flow: column;
            }
            .langtit {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: "Space Mono", monospace;
                color: #8c8d71;
                margin-right: 0;
                line-height: 24px;
                margin-top: 3px;
                align-self: flex-end;
                margin-left: -20px;
                text-decoration: none;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    order: 2;
                    align-self: flex-start;
                    margin-top: 25px;
                    margin-left: 5px;
                }
                &:before {
                    content: "";
                    width: 12px;
                    height: 12px;
                    margin-top: 2px;
                    margin-right: 4px;
                    background: url(../../../assets/images/visitsite.svg) no-repeat;
                    background-size: 100%;
                }
            }

            .lngss {
                width: calc(100% - 165px);
                margin-left: auto;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    width: 100%;
                }
                .heading {
                    font-family: "CircularStd";
                    font-size: 85px;
                    font-weight: normal;
                    letter-spacing: -1.36px;
                    color: #862ccb;
                    margin-bottom: 60px;
                    line-height: 95px;
                    display: flex;
                    align-items: center;
                    a {
                        font-family: "CircularStd";
                        font-size: 85px;
                        font-weight: normal;
                        letter-spacing: -1.36px;
                        color: #862ccb;
                        line-height: 95px;
                        display: flex;
                        align-items: center;
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            flex-flow: column;
                            align-items: flex-start;
                        }
                        &:after {
                            content: "";
                            width: 55px;
                            height: 55px;
                            background: url(../../../assets/images/nextprojArrow.svg) no-repeat;
                            background-size: 100%;
                            margin-left: 10px;
                        }
                    }
                }
                .content {
                    display: flex;
                    align-items: center;
                    .textsec {
                        font-family: "CircularStd";
                        font-size: 20px;
                        font-weight: 400;
                        letter-spacing: -0.32px;
                        color: #360e02;
                        line-height: 25px;
                        width: 100%;
                        max-width: 274px;
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            display: none;
                        }

                        .boldtxt {
                            font-weight: 700;
                        }
                    }
                    .imagearea {
                        width: 100%;
                        max-width: calc(100% - 350px);
                        margin-left: auto;
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            width: 100%;
                            max-width: 100%;
                        }
                        img {
                            width: 100%;
                            box-shadow: 0 0px 32px 0 #200a03;
                        }
                    }
                }
            }
        }
    }
}
