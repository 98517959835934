@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";
.HeroSec {
    position: relative;
    height: 100vh;
    padding-top: 236px;
    display: flex;
    overflow: hidden;
    @media screen and (min-width: 0) and (max-width: 767px) {
        padding-top: 117px;
        height: calc(100vh - 200px);
    }
    .parent {
        width: calc(100% - 443px);
        margin-left: auto;
        position: relative;
        @media screen and (min-width: 0) and (max-width: 767px) {
            width: calc(100% - 85px);
            max-width: 258px;
            margin-left: 89px;
        }
        h1 {
            font-family: "CircularStd";
            font-weight: 500;
            font-size: 60px;
            letter-spacing: -0.96px;
            color: #eeeeea;
            max-width: 720px;
            line-height: 65px;
            @media screen and (min-width: 0) and (max-width: 767px) {
                font-size: 40px;
                line-height: 45px;
            }
        }
        .herosub {
            max-width: 720px;
            margin-right: auto;
            p {
                max-width: 390px;
                margin-left: auto;
                font-family: "CircularStd";
                font-size: 18px;
                line-height: 24px;
                letter-spacing: -0.29px;
                color: #f0f700;
                font-weight: 400;
            }
        }
        .drop-parent {
            position: absolute;
            max-width: 720px;
            left: 0;
            right: 0;
            height: 100%;
            top: 0;
            margin-right: auto;
            @media screen and (min-width: 0) and (max-width: 767px) {
                left: -110px;
            }
            #pool {
                height: calc(100% + 90px);
                margin-top: -60px;
                @media screen and (min-width: 1600px) and (max-width: 1920px) {
                    height: calc(100% + 180px);
                    margin-top: -120px;
                }
            }
            .dropbox {
                max-width: 423px;
                margin-left: auto;
                margin-top: 185px;
                height: calc(100% - 185px);
                display: flex;
                flex-flow: column;
                .drop {
                    box-sizing: border-box;
                    position: relative;
                    display: block;
                    width: 15px;
                    height: 21px;
                    position: relative;
                    top: 0;
                    margin: 0 auto;
                    -moz-animation-name: dripp;
                    -webkit-animation-name: dripp;
                    animation-name: dripp;
                    // -moz-animation-timing-function: cubic-bezier(1, 0, 0.91, 0.19);
                    -moz-animation-timing-function: ease-in;
                    -webkit-animation-timing-function: ease-in;
                    animation-timing-function: ease-in;
                    -moz-animation-duration: 2s;
                    -webkit-animation-duration: 2s;
                    animation-duration: 2s;
                    -moz-animation-iteration-count: infinite;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
                    background: url(../../../assets/images/drop.svg) no-repeat;
                    background-size: 100%;
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        margin-top: 25px;
                        // margin-left: 66px;
                    }
                }
                .wave {
                    position: relative;
                    opacity: 0;
                    top: 0;
                    width: 2px;
                    height: 1px;
                    border: #260a02 7px solid;
                    margin: 0 auto;
                    -moz-border-radius: 300px / 150px;
                    -webkit-border-radius: 300px / 150px;
                    border-radius: 300px / 150px;
                    -moz-animation-name: ripple;
                    -webkit-animation-name: ripple;
                    animation-name: ripple;
                    -moz-animation-delay: 2s;
                    -webkit-animation-delay: 2s;
                    animation-delay: 2s;
                    -moz-animation-duration: 2s;
                    -webkit-animation-duration: 2s;
                    animation-duration: 2s;
                    -moz-animation-iteration-count: infinite;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
                    background: #260a02;
                    margin-top: auto;
                    margin-bottom: 16px;
                }
                .wave:after {
                    content: "";
                    position: absolute;
                    opacity: 0;
                    top: -5px;
                    left: -5px;
                    width: 2px;
                    height: 1px;
                    border: #360e02 5px solid;
                    -moz-border-radius: 300px / 150px;
                    -webkit-border-radius: 300px / 150px;
                    border-radius: 300px / 150px;
                    -moz-animation-name: ripple-2;
                    -webkit-animation-name: ripple-2;
                    animation-name: ripple-2;
                    -moz-animation-duration: 2s;
                    -webkit-animation-duration: 2s;
                    animation-duration: 2s;
                    -moz-animation-iteration-count: infinite;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
                }
                .wave:before {
                    content: "";
                    position: absolute;
                    opacity: 0;
                    top: -5px;
                    left: -5px;
                    width: 2px;
                    height: 1px;
                    border: #360e02 5px solid;
                    -moz-border-radius: 300px / 150px;
                    -webkit-border-radius: 300px / 150px;
                    border-radius: 300px / 150px;
                    -moz-animation-name: ripple-3;
                    -webkit-animation-name: ripple-3;
                    animation-name: ripple-3;
                    -moz-animation-duration: 2s;
                    -webkit-animation-duration: 2s;
                    animation-duration: 2s;
                    -moz-animation-iteration-count: infinite;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
                }
                @keyframes ripple {
                    from {
                        opacity: 1;
                    }
                    to {
                        width: 200px;
                        height: 75px;
                        border-width: 1px;
                        top: 0;
                        opacity: 0;
                    }
                }
                @keyframes ripple-2 {
                    0% {
                        opacity: 1;
                    }
                    70% {
                        opacity: 0;
                    }
                    100% {
                        width: 50px;
                        height: 25px;
                        border-width: 1px;
                        top: 15px;
                        left: 55px;
                    }
                }
                @keyframes ripple-3 {
                    0% {
                        opacity: 1;
                    }
                    80% {
                        opacity: 0;
                    }
                    100% {
                        width: 100px;
                        height: 40px;
                        border-width: 1px;
                        top: 5px;
                        left: 35px;
                    }
                }
                @keyframes dripp {
                    0% {
                        top: 0;
                    }
                    16% {
                        top: calc(100% - 16px);
                        opacity: 0;
                    }
                    100% {
                        opacity: 0;
                        top: calc(100% - 16px);
                    }
                }
            }
        }
    }
}