@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.smallpart {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    padding-top: 0;
    @media screen and (min-width: 0) and (max-width: 767px) {
        width: calc(100% - 85px);
    }
    .titlesec {
        display: flex;
        flex-flow: column;
        margin-bottom: 66px;
        .maintitle {
            display: flex;
            align-items: flex-start;
            @media screen and (min-width: 0) and (max-width: 767px) {
                flex-flow: column;
            }
            .leftrightparent {
                width: calc(100% - 165px);
                margin-left: auto;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    width: 100%;
                }
                .content {
                    display: flex;
                    align-items: center;
                    margin-bottom: 0px;
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        flex-flow: column;
                        align-items: flex-start;
                        margin-top: 50px;
                    }
                    &.margintop {
                        margin-top: 80px;
                    }
                    .textsec {
                        font-family: "CircularStd";
                        font-size: 20px;
                        font-weight: 400;
                        letter-spacing: -0.32px;
                        color: #360e02;
                        line-height: 25px;
                        width: 100%;
                        max-width: 274px;
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            order: 1;
                        }

                        .boldtxt {
                            font-weight: 700;
                            margin-bottom: 5px;
                        }
                        &.secrow {
                            margin-left: 30px;
                            @media screen and (min-width: 0) and (max-width: 767px) {
                               margin-left: 0;
                            }
                        }
                    }
                    .imagearea {
                        width: 100%;
                        max-width: calc(100% - 400px);
                        margin-left: auto;
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            width: 100%;
                            max-width: 100%;
                            order: 2;
                        }
                        &.leftmar0 {
                            margin-left: 0;
                        }
                        img {
                            width: 100%;
                            // box-shadow: 0 0px 32px 0 #200a03;
                        }
                    }
                    .order1 {
                        order: 1;
                    }
                    .order2 {
                        order: 2;
                    }
                }
            }
        }
    }
}
