@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";
.Whatwedohero {
    position: relative;
    height: auto;
    padding-top: 236px;
    display: flex;
    overflow: hidden;
    @media screen and (min-width: 0) and (max-width: 767px) {
        padding-top: 125px;
    }
    .parent {
        width: calc(100% - 443px);
        margin-left: auto;
        position: relative;
        @media screen and (min-width: 0) and (max-width: 767px) {
            max-width: calc(100% - 85px);
            width: calc(100% - 85px);
        }
        h1 {
            font-family: "CircularStd";
            font-weight: 500;
            font-size: 60px;
            letter-spacing: -0.96px;
            color: #eeeeea;
            max-width: 720px;
            line-height: 65px;
            @media screen and (min-width: 0) and (max-width: 767px) {
                max-width: 296px;
                font-size: 40px;
                line-height: 45px;
            }
        }
        .serviceparentbox {
            margin-top: 90px;
            max-width: 850px;
            width: 100%;
            display: flex;
            flex-flow: row wrap;
            position: relative;
            @media screen and (min-width: 0) and (max-width: 767px) {
                flex-flow: column;
            }
            &:before {
                content: "Services";
                font-family: "Space Mono", monospace;
                font-weight: 400;
                font-size: 16px;
                font-weight: normal;
                letter-spacing: -0.26px;
                color: #8c8d71;
                position: absolute;
                left: -90px;
                top: 95px;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    position: relative;
                    top: auto;
                    left: auto;
                    margin-bottom: 20px;
                }
            }
            >div {
                cursor: pointer;
                width: 100%;
                max-width: 220px;
                margin-bottom: 70px;
                margin-right: 95px;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    max-width: 275px;
                    margin-right: 0;
                    padding-bottom: 30px;
                    margin-bottom: 30px;
                    border-bottom: 1px solid #862ccb;
                    &.active {
                        border-color: #fff;
                    }
                }
                &:nth-child(3) {
                    margin-right: 0;
                }
                span {
                    margin-bottom: 18px;
                    min-width: 50px;
                    max-width: 70px;
                    display: flex;
                    svg {
                        width: 100%;
                        // &:last-child {
                        //     display: none;
                        // }
                        &:last-child {
                            display: none;
                        }
                        &:first-child {
                            display: flex;
                        }
                        defs {
                            &+g {
                                transform: scale(1.6) translate(-105px, -106px);
                            }
                        }
                    }
                    &#icons3 {
                        svg {
                            defs {
                                &+g {
                                    transform: scale(1.6) translate(-85px, -106px);
                                }
                            }
                        }
                    }
                    &#icons4 {
                        svg {
                            defs {
                                &+g {
                                    transform: scale(1.4) translate(-105px, -106px);
                                }
                            }
                        }
                    }
                }
                p {
                    font-family: "CircularStd";
                    font-size: 29px;
                    line-height: 29px;
                    font-weight: 400;
                    letter-spacing: -0.56px;
                    color: #fff;
                    display: inline-block;
                    svg {
                        margin-bottom: 0;
                        width: 20px;
                        height: 20px;
                        display: inherit;
                        background-size: 100%;
                        margin-left: 5px;
                        transform: rotate(180deg);
                        transition: all 0.3s linear;
                        g {
                            g {
                                stroke: #eeeeea;
                                transition: all 0.3s linear;
                            }
                        }
                    }
                }
                >div {
                    display: none;
                    position: absolute;
                    left: 0;
                    border-top: 1px solid #8c8d71;
                    padding-top: 15px;
                    width: 100%;
                    min-width: 630px;
                    max-width: 630px;
                    padding-right: 87px;
                    font-family: "CircularStd";
                    font-size: 18px;
                    font-weight: normal;
                    letter-spacing: -0.29px;
                    color: #fff;
                    line-height: 26px;
                    margin-top: 60px;
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        margin-top: 29px;
                        border: none;
                        padding-bottom: 30px;
                        min-width: 100%;
                        padding-right: 15px;
                    }
                    span {
                        margin-top: 8px;
                        display: flex;
                        font-size: 16px;
                        font-weight: normal;
                        color: #a1a288;
                        align-items: center;
                        cursor: pointer;
                        font-family: "Space Mono", monospace;
                        &:before {
                            content: "";
                            width: 10px;
                            height: 10px;
                            background: url(../../../assets/images/close.svg) no-repeat;
                            background-size: 100%;
                            margin-right: 5px;
                        }
                    }
                }
                &.active {
                    #Oval-Copy-4,
                    #Oval-Copy-2 {
                        fill: #f0f700;
                        transition: all 0.2s linear;
                    }
                    >div {
                        display: flex;
                        flex-flow: column;
                        &.content {
                            animation: fade_in_show 0.5s;
                            z-index: 99;
                        }
                        &.hidden {
                            position: relative;
                            opacity: 0;
                        }
                    }
                    p {
                        color: #862ccb;
                        transition: all 0.2s linear;
                        svg {
                            margin-bottom: 0;
                            background-size: 100%;
                            margin-left: 5px;
                            transform: rotate(360deg);
                            transition: all 0.3s linear;
                            g {
                                g {
                                    stroke: #862ccb;
                                    transition: all 0.3s linear;
                                }
                            }
                        }
                    }
                    span {
                        svg {
                            &:first-child {
                                display: none;
                            }
                            &:last-child {
                                display: flex;
                            }
                        }
                    }
                    @keyframes showactivearrow {
                        from {
                            transform: rotate(0);
                        }
                        to {
                            transform: rotate(160deg);
                        }
                    }
                    @keyframes fade_in_show {
                        0% {
                            opacity: 0;
                            transform: scale(0);
                        }
                        100% {
                            opacity: 1;
                            transform: scale(1);
                        }
                    }
                }
                &:nth-child(2) {
                    &.active {
                        >div {
                            margin-top: 31px;
                        }
                    }
                }
                &:nth-child(4) {
                    &.active {
                        >div {
                            margin-top: 34px;
                        }
                    }
                }
            }
        }
    }
}