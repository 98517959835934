@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.HomeourWork {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    @media screen and (min-width: 0) and (max-width: 767px) {
        max-width: calc(100% - 85px);
        width: calc(100% - 85px);
    }
    .titlesec {
        display: flex;
        flex-flow: column;
        margin-bottom: 66px;
        .maintitle {
            display: flex;
            align-items: flex-start;
            @media screen and (min-width: 0) and (max-width: 767px) {
                flex-flow: column;
            }
            a {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: "Space Mono", monospace;
                color: #97987c;
                margin-right: 20px;
                line-height: 24px;
                margin-top: 3px;
                &:after {
                    content: "";
                    background: url(../../../assets/images/homeviewup.svg) no-repeat;
                    width: 12px;
                    height: 12px;
                    margin-left: 4px;
                }
            }
            p {
                max-width: 720px;
                font-size: 45px;
                font-weight: 500;
                line-height: 55px;
                letter-spacing: -0.72px;
                color: #eeeeea;
                font-family: "CircularStd";
                @media screen and (min-width: 0) and (max-width: 767px) {
                    max-width: 270px;
                    font-size: 30px;
                    line-height: 35px;
                }
            }
        }
        .subtitle {
            display: flex;
            width: 100%;
            max-width: 910px;
            margin-right: auto;
            margin-top: 40px;

            p {
                font-family: "CircularStd";
                font-size: 18px;
                font-weight: 400;
                letter-spacing: -0.29px;
                color: #f0f700;
                margin-left: auto;
                max-width: 500px;
                padding-right: 20px;
            }
        }
    }
    .imagearea {
        width: calc(100% - 165px);
        margin-left: auto;
        @media screen and (min-width: 0) and (max-width: 767px) {
            width: calc(100% + 85px);
            margin-left: -85px;
        }
        img {
            width: 100%;
            box-shadow: 0 32px 94px 0 #200a03;
        }
    }
}
