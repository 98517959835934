@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.ProjectHero {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    padding-top: 236px;
    padding-bottom: 95px;
    @media screen and (min-width: 0) and (max-width: 767px) {
        width: calc(100% - 85px);
        padding-top: 117px;
    }
    .titlesec {
        display: flex;
        flex-flow: column;
        margin-bottom: 66px;
        .maintitle {
            display: flex;
            align-items: flex-start;
            a {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: "Space Mono", monospace;
                color: #97987c;
                margin-right: 20px;
                line-height: 24px;
                margin-top: 3px;
                &:before {
                    content: "";
                    background: url(../../../assets/images/visitsite.svg) no-repeat;
                    width: 12px;
                    height: 12px;
                    margin-right: 4px;
                }
            }
            p {
                max-width: 833px;
                font-size: 60px;
                font-weight: 500;
                line-height: 65px;
                letter-spacing: -0.72px;
                color: #3e0b01;
                font-family: "CircularStd";
                margin-left: 160px;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    margin-left: 0;
                    font-size: 40px;
                    line-height: 45px;
                }
            }
        }
    }
    .projectabout {
        display: flex;
        padding-left: 160px;
        flex-flow: row wrap;
        @media screen and (min-width: 0) and (max-width: 767px) {
            padding-left: 0;
            flex-flow: column;
            max-width: 296px;
        }
        .title {
            font-family: "CircularStd";
            font-size: 18px;
            font-weight: 700;
            line-height: 20px;
            color: #862ccb;
        }
        .content {
            font-family: "CircularStd";
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            color: #360e02;
        }
        .client {
            width: 180px;
            @media screen and (min-width: 0) and (max-width: 767px) {
                width: 100%;
                margin: 0;
                margin-bottom: 20px;
            }
        }
        .brief {
            width: calc(100% - 300px);
            margin-right: 41px;
            @media screen and (min-width: 0) and (max-width: 767px) {
                width: 100%;
                margin: 0;
                margin-bottom: 20px;
            }
        }
        .credits {
            width: 100%;
            margin-left: 180px;
            margin-top: 30px;
            @media screen and (min-width: 0) and (max-width: 767px) {
                width: 100%;
                margin: 0;
            }
            p {
                padding: 0;
                margin: 0;
                margin-bottom: 5px;
                span {
                    font-weight: 700;
                }
            }
        }
    }
}
