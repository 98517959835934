@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.Viewourwork {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    padding-top: 300px;
    padding-bottom: 150px;
    @media screen and (min-width: 0) and (max-width: 767px) {
        max-width: 100%;
        width: 100%;
        padding-top: 124px;
        padding-bottom: 58px;
    }
    .titlesec {
        display: flex;
        flex-flow: column;
        margin-bottom: 66px;
        .maintitle {
            display: flex;
            align-items: flex-start;
            @media screen and (min-width: 0) and (max-width: 767px) {
                flex-flow: column;
            }
            a {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: "Space Mono", monospace;
                color: #97987c;
                margin-right: 20px;
                line-height: 24px;
                margin-top: 3px;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    padding-bottom: 27px;
                    padding-left: 85px;
                }
                
                &:after {
                    content: "";
                    background: url(../../../assets/images/homeviewup.svg) no-repeat;
                    width: 12px;
                    height: 12px;
                    margin-left: 4px;
                }
            }
            p {
                max-width: 720px;
                font-size: 45px;
                font-weight: 500;
                line-height: 55px;
                letter-spacing: -0.72px;
                color: #eeeeea;
                font-family: "CircularStd";
            }
            .imagearea {
                width: calc(100% - 165px);
                margin-left: auto;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    width: 100%;
                }
                .carousel {
                    display: flex;
                    flex-flow: row wrap;
                    .control-dots {
                        display: none;
                    }
                    .slider-wrapper {
                        width: 100%;
                        order: 1;
                        ul {
                            li {
                                div {
                                    img {
                                        width: 100%;
                                        box-shadow: 15px 0 22px 0 #200a03;
                                    }
                                    .sidercontent {
                                        font-family: 'CircularStd';
                                        max-width: 417px;
                                        font-size: 18px;
                                        font-weight: normal;
                                        color: #eeeeea;
                                        text-align: left;
                                        line-height: 24px;
                                        padding-top: 26px;
                                        padding-bottom: 8px;
                                        @media screen and (min-width: 0) and (max-width: 767px) {
                                            padding-left: 85px;
                                        }
                                        span {
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    button {
                        position: relative;
                        top: 0;
                        right: auto;
                        bottom: auto;
                        left: 0;
                        padding: 0;
                        margin: 0;
                        opacity: 1;
                        background: transparent;
                        display: flex;
                        align-items: center;
                        &.control-prev {
                            order: 2;
                            @media screen and (min-width: 0) and (max-width: 767px) {
                                padding-left: 85px;
                            }
                            &::before {
                                content: '';
                                border: none;
                                width: 15px;
                                height: 15px;
                                background: url(../../../assets/images/whatwedo/prevarrow.svg) no-repeat;
                                background-size: 100%;
                            }
                        }
                        &.control-next {
                            order: 4;
                            &::before {
                                content: '';
                                border: none;
                                width: 15px;
                                height: 15px;
                                background: url(../../../assets/images/whatwedo/nextarrow.svg) no-repeat;
                                background-size: 100%;
                            }
                        }
                    }
                    .carousel-status {
                        order: 3;
                        font-family: "Space Mono", monospace;
                        position: relative;
                        font-size: 16px;
                        letter-spacing: -3.26px;
                        color: #eeeeea;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}
