@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.appscreens {
    width: 100%;
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 120px;
    @media screen and (min-width: 0) and (max-width: 767px) {
        padding: 0;
        margin-top: 60px;
    }
    .imagearea {
        width: 100%;
        img {
            width: 100%;
            @media screen and (min-width: 0) and (max-width: 767px) {
                height: 669px;
                object-fit: cover;
            }
        }
    }
}
