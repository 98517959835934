@import "../../styles/variables/colors";
@import "../../styles/variables/mixins";
.ContactPage {
    background: #360E02;
    .contactclock {
        > div {
            &:before {
                left: -113px;
            }
        }
    }
}
