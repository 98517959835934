@import "../../styles/variables/colors";
@import "../../styles/variables/mixins";

.footer {
  padding-left: 42px;
  display: flex;
  padding-bottom: 197px;
  @media screen and (min-width: 0) and (max-width: 767px) {
    padding-left: 85px;
    padding-bottom: 100px;
  }
  .logo {
    width: 217px;
    margin-right: 173px;
    opacity: 1;
    position: relative;
    z-index: 99;
    opacity: 0;
    transition: opacity 1.3s ease-in-out;
    @media screen and (min-width: 0) and (max-width: 767px) {
      display: none;
    }
    &.active {
      opacity: 1;
     transition: all 1.3s ease-in-out;
    }
    // @keyframes opacityanim {
    //   0% {
    //     opacity: 0;
    //   }
    //   100% {
    //     opacity: 1;
    //   }
      
    // }

    svg,
    img {
      width: 100%;
    }
  }
  .rightsec {
    padding-top: 48px;
    display: flex;
    @media screen and (min-width: 0) and (max-width: 767px) {
      flex-flow: column;
    }
    ul {
      list-style: none;
      &.links {
        width: 142px;
        margin-right: 30px;
        @media screen and (min-width: 0) and (max-width: 767px) {
          width: 100%;
          max-width: 223px;
        }
        li {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          @media screen and (min-width: 0) and (max-width: 767px) {
            width: 100%;
          }
          &:last-child {
            margin-bottom: 0;
          }
          a {
            font-family: "Space Mono", monospace;
            font-size: 16px;
            font-weight: normal;
            color: #8c8d71;
            transition: all 0.2s ease-in-out;
            line-height: 24px;
            @media screen and (min-width: 0) and (max-width: 767px) {
              display: flex;
              width: 100%;
              flex-flow: column;
              &:after {
                content: attr(data-title);
                color: #eeeeea;
              }
            }
            &.is-active {
              color: #f0f700;
              transition: all 0.2s ease-in-out;
            }
            &:hover {
              color: #f0f700;
              transition: all 0.2s ease-in-out;
            }
          }
        }
      }
      &.text {
        margin-right: 120px;
        @media screen and (min-width: 0) and (max-width: 767px) {
          display: none;
        }
        li {
          font-family: "CircularStd";
          font-size: 16px;
          font-weight: normal;
          color: #eeeeea;
          line-height: 24px;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &.social {
        display: flex;
        width: 170px;
        justify-content: space-between;
        @media screen and (min-width: 0) and (max-width: 767px) {
          margin-top: 40px;
        }
        img {
          width: 30px;
        }
      }
    }
  }
  &.footer_greys {
    // .logo {
    //   top: 41px;
    // }
    .rightsec {
      ul {
        &.links {
          li {
            a {
              transition: all 0.2s ease-in-out;
              @media screen and (min-width: 0) and (max-width: 767px) {
                &::after {
                  color: #360e02;
                }
              }
              &.is-active {
                color: #862ccb;
                transition: all 0.2s ease-in-out;
              }
              &:hover {
                color: #862ccb;
                transition: all 0.2s ease-in-out;
              }
            }
          }
        }
        &.text {
          li {
            color: #360e02;
          }
        }
      }
    }
  }
}
