@import "../../styles/variables/colors";
@import "../../styles/variables/mixins";
.navbar {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    z-index: 99;
    // background: #360E02;
    // &.navbarother {
    //   background: #eeeeea;
    // }
    .mobilemenu {
        display: none;
        @media screen and (min-width: 0) and (max-width: 767px) {
            display: flex;
            position: fixed;
            top: 36px;
            right: 30px;
            align-items: center;
            font-size: 16px;
            font-weight: normal;
            color: #a1a288;
            font-family: "Space Mono", monospace;
            padding-right: 20px;
            &:after {
                content: "";
                width: 12px;
                height: 12px;
                background: url(../../assets/images/mobilemenuinactive.svg) no-repeat;
                background-size: 100%;
                margin-left: 10px;
                opacity: 1;
                transition: all 0.5s ease-in-out;
                position: absolute;
                right: 0;
            }
            &::before {
                content: "";
                width: 12px;
                height: 12px;
                background: url(../../assets/images/mobilemenuactive.svg) no-repeat;
                background-size: 100%;
                margin-left: 10px;
                opacity: 0;
                transition: all 0.5s ease-in-out;
                position: absolute;
                right: 0;
            }
        }
    }
    &.navbarother {
        >a {
            .logo {
                background: #eeeeea;
                transition: all 0.6s ease-in-out;
            }
        }
    }
    >a {
        width: 236px;
        margin-right: 208px;
        display: flex;
        align-items: center;
        position: fixed;
        left: 0;
        top: 10px;
        @media screen and (min-width: 0) and (max-width: 767px) {
            width: 90px;
            margin-right: 15px;
            z-index: 9;
        }
        .logo {
            display: flex;
            align-items: center;
            background-image: radial-gradient(#360e02 0%, transparent 60%);
            width: 236px;
            height: 236px;
            @media screen and (min-width: 0) and (max-width: 767px) {
                width: 95px;
                height: 95px;
            }
            #logolottie {
                width: 100%;
                height: 236px;
                transform: none !important;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    height: 95px;
                }
            }
            svg {
                // transform: rotate(45deg);
                transform: none !important;
                opacity: 1;
                transition: opacity 1s ease-in-out;
                width: 100%;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    width: 95px;
                    height: 95px !important;
                }
                defs {
                    &+g {
                        transform: scale(1.1) translate(-59px, -75px)
                    }
                }
                path {
                    // opacity: 0;
                    // transition: all 0.2s ease-in-out;
                    // &.a1 {
                    //   // opacity: 1;
                    // }
                    // &.visible {
                    //   opacity: 1;
                    // }
                }
                &.dropp {
                    opacity: 0;
                    transition: opacity 1s ease-in-out;
                }
            }
            img {
                width: 100%;
                max-width: 100%;
            }
        }
        &+.mobilemenuname {
            display: none;
            @media screen and (min-width: 0) and (max-width: 767px) {
                display: flex;
                position: fixed;
                top: 36px;
                left: 100px;
                color: #f0f700;
                font-size: 16px;
                font-family: "Space Mono", monospace;
            }
        }
    }
    .menues {
        @media screen and (min-width: 0) and (max-width: 767px) {
            position: fixed;
            top: 0;
            left: -100%;
            height: 100%;
            width: 100%;
            background: #360e02;
            transition: all 0.6s ease-in-out;
        }
        ul {
            display: flex;
            list-style: none;
            align-items: center;
            padding: 0;
            margin-left: 409px;
            height: 250px;
            @media screen and (min-width: 0) and (max-width: 767px) {
                margin-left: 0;
                height: auto;
                flex-flow: column;
                align-items: flex-start;
                padding-top: 100px;
            }
            li {
                margin-right: 75px;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    margin-right: 0;
                    opacity: 0;
                    transition: all 0.3s ease;
                    transform: translateX(-100px);
                    border-bottom: 1px solid #862ccb;
                    padding-bottom: 20px;
                    padding-top: 15px;
                    min-width: 65%;
                    // transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
                }
                a {
                    color: #eeeeea;
                    text-decoration: none;
                    font-size: 16px;
                    font-family: "Space Mono", monospace;
                    position: relative;
                    transition: all 0.2s linear;
                    &:before {
                        content: "";
                        width: 1px;
                        height: 24px;
                        background: #eeeeea;
                        position: absolute;
                        top: -35px;
                        opacity: 1;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        transition: all 0.2s linear;
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            display: none;
                        }
                    }
                    &:hover {
                        opacity: 1;
                        color: #f0f700;
                        transition: all 0.2s linear;
                        &:before {
                            opacity: 1;
                            background: #92928b;
                            transition: all 0.2s linear;
                        }
                    }
                    &.is-active {
                        opacity: 1;
                        color: #f0f700;
                        transition: all 0.2s linear;
                        &:before {
                            opacity: 1;
                            background: #f0f700;
                            transition: all 0.2s linear;
                        }
                    }
                }
                &.mobilesocialheader {
                    display: none;
                    border: none;
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        display: flex;
                        a {
                            display: flex;
                            margin-right: 30px;
                            img {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
        &.greymenues {
            ul {
                li {
                    a {
                        color: #3e0b01;
                        transition: all 0.2s linear;
                        &:before {
                            background: #3e0b01;
                        }
                        &:hover {
                            color: #862ccb;
                            &:before {
                                background: #862ccb;
                            }
                        }
                        &.is-active {
                            transition: all 0.2s linear;
                            color: #862ccb;
                            &:before {
                                background: #862ccb;
                            }
                        }
                    }
                }
            }
        }
    }
    &.menuopen {
        @media screen and (min-width: 0) and (max-width: 767px) {
            a {
                .logo {
                    background: #360e02;
                }
            }
        }
        .menues {
            @media screen and (min-width: 0) and (max-width: 767px) {
                left: 0;
                ul {
                    li {
                        opacity: 1;
                        transform: translateX(95px);
                        transition: opacity 0.6s, transform 1.6s ease-in-out;
                        a {
                            color: #fff;
                            &.is-active {
                                color: #f0f700;
                            }
                        }
                    }
                }
            }
        }
        .mobilemenu {
            color: #f0f700;
            transition: all 0.5s ease-in-out;
            &::before {
                opacity: 1;
            }
            &::after {
                opacity: 0;
            }
        }
    }
}