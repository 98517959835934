@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";
.Technologieswhatwedo {
    position: relative;
    height: auto;
    padding-top: 236px;
    display: flex;
    overflow: hidden;
    flex-flow: column;
    @media screen and (min-width: 0) and (max-width: 767px) {
        padding-top: 92px;
    }
    .parent {
        // width: calc(100% - 443px);
        margin-left: auto;
        position: relative;
        .title {
            font-family: "CircularStd";
            font-size: 18px;
            font-weight: 500;
            letter-spacing: -0.29px;
            color: #f0f700;
            margin-bottom: 8px;
            &:after {
                content: attr(data-title);
                font-family: "CircularStd";
                font-size: 18px;
                font-weight: 500;
                letter-spacing: -0.29px;
                color: #f0f700;
                margin-bottom: 8px;
                left: 443px;
                position: relative;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    left: 84px;
                }
            }
            &+div {
                font-family: "CircularStd";
                display: flex;
                justify-content: flex-end;
                font-size: 75px;
                letter-spacing: -1.36px;
                color: #862ccb;
                font-weight: 400;
                white-space: nowrap;
                -moz-transform: translateX(100%);
                -webkit-transform: translateX(100%);
                transform: translateX(100%);
                @media screen and (min-width: 0) and (max-width: 767px) {
                    font-size: 36px;
                }
                .Cursor {
                    display: none;
                }
            }
        }
        &:first-child {
            .title {
                &:before {
                    content: "Technologies";
                    font-family: "Space Mono", monospace;
                    font-weight: 400;
                    font-size: 16px;
                    font-weight: normal;
                    letter-spacing: -0.26px;
                    color: #8c8d71;
                    position: absolute;
                    left: 313px;
                    top: -3px;
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        left: 82px;
                        right: auto;
                        top: -40px;
                    }
                }
            }
        }
        &.scroll0 {
            .title {
                &+div {
                    animation: my-animation 15s linear infinite;
                    animation-delay: -1s;
                }
            }
        }
        &.scroll1 {
            .title {
                &+div {
                    animation: my-animation 15s linear infinite;
                    animation-delay: 3s;
                }
            }
        }
        &.scroll2 {
            .title {
                &+div {
                    animation: my-animation 15s linear infinite;
                    animation-delay: 6s;
                }
            }
        }
        &.scroll3 {
            .title {
                &+div {
                    animation: my-animation 15s linear infinite;
                    animation-delay: 9s;
                }
            }
        }
        @keyframes my-animation {
            from {
                transform: translateX(100%);
            }
            to {
                transform: translateX(-100%);
            }
        }
    }
}