@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.homewhatwedo {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    padding-top: 250px;
    padding-bottom: 0;
    @media screen and (min-width: 0) and (max-width: 767px) {
        max-width: calc(100% - 85px);
        width: calc(100% - 85px);
        padding-top: 60px;
    }
    .titlesec {
        display: flex;
        flex-flow: column;
        margin-bottom: 400px;
        @media screen and (min-width: 0) and (max-width: 767px) {
            margin-bottom: 220px;
        }
        .maintitle {
            display: flex;
            align-items: flex-start;
            @media screen and (min-width: 0) and (max-width: 767px) {
                flex-flow: column;
            }
            a {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: "Space Mono", monospace;
                color: #97987c;
                margin-right: 20px;
                line-height: 24px;
                margin-top: 3px;
                &:after {
                    content: "";
                    background: url(../../../assets/images/homeviewup.svg) no-repeat;
                    width: 12px;
                    height: 12px;
                    margin-left: 4px;
                }
            }
            p {
                max-width: 775px;
                font-size: 45px;
                font-weight: 500;
                line-height: 55px;
                letter-spacing: -0.72px;
                color: #eeeeea;
                font-family: "CircularStd";
                @media screen and (min-width: 0) and (max-width: 767px) {
                    max-width: 280px;
                    font-size: 30px;
                    line-height: 35px;
                }
                span {
                    color: #f0f700;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
        .wedoanimation {
            margin-right: auto;
            display: flex;
            max-width: 1070px;
            width: 100%;
            justify-content: flex-end;
            // svg {
            //     margin-right: 260px;
            //     margin-top: 80px;
            // }
            svg {
                margin-top: 0;
                min-width: 250px;
                min-height: 250px;
                max-width: 250px;
                max-height: 250px;
                opacity: 0.5;
                position: absolute;
                right: 250px;
                top: 400px;
                transition: all 1s ease-in-out;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    min-width: 120px;
                    min-height: 120px;
                    max-width: 120px;
                    max-height: 120px;
                    right: 20px;
                    top: 300px;
                }
                &.bigger {
                    min-width: 350px;
                    min-height: 350px;
                    max-width: 350px;
                    max-height: 350px;
                    opacity: 1 !important;
                    transition: all 1s ease-in-out;
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        min-width: 180px;
                        min-height: 180px;
                        max-width: 180px;
                        max-height: 180px;
                    }
                }
                &.fluid {
                    opacity: 0;
                    overflow: hidden;
                }
                &.structure {
                    min-width: 350px;
                    min-height: 350px;
                    max-width: 350px;
                    max-height: 350px;
                    opacity: 1;
                    transition: all 1s ease-in-out;
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        min-width: 180px;
                        min-height: 180px;
                        max-width: 180px;
                        max-height: 180px;
                    }
                }
                &.fluidanim {
                    margin-right: 0 !important;
                    transition: all 1s ease-in-out;
                    opacity: 1;
                    min-width: 150px;
                    min-height: 150px;
                    max-width: 150px;
                    max-height: 150px;
                    display: flex;
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        min-width: 70px;
                        min-height: 70px;
                        max-width: 70px;
                        max-height: 70px;
                    }
                    &:nth-child(2) {
                        // animation: showotherbox 0.3s ease-in-out forwards;
                        right: 380px;
                        top: 420px;
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            right: 80px;
                            top: 310px; 
                        }
                    }
                    &:nth-child(3) {
                        // animation: showotherbox1 0.3s ease-in-out forwards;
                        right: 520px;
                        top: 430px;
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            right: 150px;
                            top: 320px; 
                        }
                    }
                    &:nth-child(4) {
                        // animation: showotherbox2 0.3s ease-in-out forwards;
                        right: 650px;
                        top: 410px;
                        @media screen and (min-width: 0) and (max-width: 767px) {
                            right: 220px;
                            top: 300px; 
                        }
                    }
                }
                // @keyframes showotherbox {
                //     to {
                //         right: 380px;
                //         top: 420px;
                //     }
                // }
                // @keyframes showotherbox1 {
                //     to {
                //         right: 520px;
                //         top: 430px;
                //     }
                // }
                // @keyframes showotherbox2 {
                //     to {
                //         right: 650px;
                //         top: 410px;
                //     }
                // }
                &.bounce {
                    animation: bounce;
                    // animation-direction: alternate;
                    // animation-timing-function: cubic-bezier(0.5, 0.05, 1, 0.5);
                    // animation-iteration-count: infinite;
                    animation-duration: 2s;
                    animation-iteration-count: infinite;
                    animation-timing-function: ease;
                }
                @keyframes bounce {
                    // from {
                    //     transform: translate3d(0, 0, 0);
                    // }
                    // to {
                    //     transform: translate3d(0, 100px, 0);
                    // }
                    0% {
                        transform: translateY(0);
                    }
                    50% {
                        transform: translateY(100px);
                    }
                    
                    100% {
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}
