@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.languages {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    padding-top: 74px;
    @media screen and (min-width: 0) and (max-width: 767px) {
        width: calc(100% - 85px);
    }
    .titlesec {
        display: flex;
        flex-flow: column;
        margin-bottom: 66px;
        @media screen and (min-width: 0) and (max-width: 767px) {
            margin-bottom: 0;
        }
        .maintitle {
            display: flex;
            align-items: flex-start;
            @media screen and (min-width: 0) and (max-width: 767px) {
                flex-flow: column;
            }
            .langtit {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: "Space Mono", monospace;
                color: #8c8d71;
                margin-right: 20px;
                line-height: 24px;
                margin-top: 3px;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    margin-bottom: 20px;
                }
            }

            .lngss {
                width: calc(100% - 165px);
                margin-left: auto;
                display: flex;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    flex-flow: column;
                    width: 100%;
                }
                > div {
                    margin-right: 100px;
                    @media screen and (min-width: 0) and (max-width: 767px) {
                        margin: 0;
                        margin-bottom: 26px;
                    }
                    .titile {
                        font-family: "CircularStd";
                        font-size: 18px;
                        font-weight: 500;
                        color: #862ccb;
                        margin-bottom: 8px;
                    }
                    .langlist {
                        ul {
                            list-style: none;
                            li {
                                font-family: "CircularStd";
                                font-size: 20px;
                                font-weight: normal;
                                letter-spacing: -0.32px;
                                color: #3e0b01;
                                line-height: 30px;
                                margin-bottom: 6px;
                            }
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
