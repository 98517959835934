@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";

.Contactwhoweare {
    width: calc(100% - 279px);
    position: relative;
    margin-left: auto;
    padding-top: 140px;
    padding-bottom: 0;
    @media screen and (min-width: 0) and (max-width: 767px) {
        padding-top: 0;
        width: calc(100% - 85px);
    }
    .titlesec {
        display: flex;
        flex-flow: column;
        margin-bottom: 170px;
        @media screen and (min-width: 0) and (max-width: 767px) {
            margin-bottom: 80px;
        }
        .maintitle {
            display: flex;
            align-items: flex-start;
            @media screen and (min-width: 0) and (max-width: 767px) {
                flex-flow: column;
            }
            a {
                display: flex;
                align-items: center;
                font-size: 16px;
                font-family: "Space Mono", monospace;
                color: #97987c;
                margin-right: 20px;
                line-height: 24px;
                margin-top: 20px;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    order: 2;
                }
                &:after {
                    content: "";
                    background: url(../../../assets/images/homeviewup.svg) no-repeat;
                    width: 12px;
                    height: 12px;
                    margin-left: 4px;
                }
            }
            p {
                max-width: 775px;
                font-size: 45px;
                font-weight: 500;
                line-height: 55px;
                letter-spacing: -0.72px;
                color: #eeeeea;
                font-family: "CircularStd";
                @media screen and (min-width: 0) and (max-width: 767px) {
                    font-size: 30px;
                    line-height: 35px;
                }
                span {
                    color: #f0f700;
                    text-decoration: underline;
                }
            }
        }
        
    }
}
