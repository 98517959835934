@import "../../../styles/variables/colors";
@import "../../../styles/variables/mixins";
.Team {
    position: relative;
    height: auto;
    padding-top: 0;
    display: flex;
    overflow: hidden;
    flex-flow: column;
    .parent {
        width: calc(100% - 443px);
        margin-left: auto;
        position: relative;
        @media screen and (min-width: 0) and (max-width: 767px) {
            width: calc(100% - 85px);
            flex-flow: column;
        }
        &:before {
            content: "Lead Team";
            font-family: "Space Mono", monospace;
            font-size: 16px;
            font-weight: normal;
            letter-spacing: -0.26px;
            color: #8c8d71;
            position: absolute;
            left: -130px;
            top: -3px;
            @media screen and (min-width: 0) and (max-width: 767px) {
                position: relative;
                left: 0;
                margin-bottom: 30px;
                display: flex;
            }
        }
        .teamsbox {
            display: flex;
            flex-flow: row wrap;
            // justify-content: space-between;
            @media screen and (min-width: 0) and (max-width: 767px) {
                flex-flow: column;
            }
            >div {
                width: calc(33% - 67px);
                margin-bottom: 45px;
                max-width: 232px;
                margin-right: 40px;
                @media screen and (min-width: 0) and (max-width: 767px) {
                    width: 100%;
                    max-width: 290px;
                }
                .imagebox {
                    width: 100%;
                    min-height: 231px;
                    // background: #862ccb;
                    margin-bottom: 15px;
                    img {
                        width: 100%;
                    }
                }
                .contentbox {
                    width: 100%;
                    font-family: "CircularStd";
                    font-size: 16px;
                    letter-spacing: -0.26px;
                    color: #fff;
                    line-height: 22px;
                    .name {
                        font-weight: 700;
                    }
                    .position {
                        font-weight: 400;
                    }
                    .identity {
                        font-weight: 400;
                        font-style: italic;
                    }
                }
            }
        }
    }
}