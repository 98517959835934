* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none !important;
  text-decoration: none;
}
body {
  overflow-x:hidden;
}
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
@font-face {
	font-family: 'CircularStd';
	src: url('../src/assets/fonts/CircularStd-Book.eot');
	src: local('☺'), url('../src/assets/fonts/CircularStd-Book.woff') format('woff'), url('../src/assets/fonts/CircularStd-Book.ttf') format('truetype'), url('../src/assets/fonts/CircularStd-Book.svg') format('svg');
	font-style: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
.container {
  position: relative;
}

.page {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
